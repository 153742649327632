<template>
  <div>
    <con-tab></con-tab>
    
    <need-nei ></need-nei>
  </div>
</template>

<script>
import FromList from "@/common/input";
import ConTab from "../../components/common/ConTab";
import NeedNei from "./base/OrganizatiNei";
import HomeSwiper from "../../components/common/Swiper";
import Video from "../../components/common/videoes";
// import search from "./SearchCom/search";
import { lun_gou } from "../common/js/api";
export default {
  name: "organizati",
  components: {
    FromList,
    HomeSwiper,
    ConTab,
    NeedNei,
    // search,
      Video
  },
  data() {
    return {
        search: "",
        imgList: [],
    };
  },
  created() {
    this.lun_bo();
  },
  methods: {

    //机构云轮播
    lun_bo() {
      lun_gou({})
        .then((res) => {
          this.imgList = res.data;
        })
        .catch((err) => {});
    },
  },
};
</script>

<style scoped lang="less">
  .big_con{
    z-index: 9;
    width:1200px;
    margin: 0 auto;
  }
  .img_box{
    position: absolute;
    margin-top: -220px;
    margin-left: 325px;
    z-index: 9;
    width: 550px;
    height: 160px;
    img{
      width: 100%;
      height: auto;
    }
  }
.swiper_class {
  position: relative;
}
.nei_box {
  position: absolute;
  top: 150px;
  z-index: 9999;
}
</style>