<template>
  <div class="big_container">
    <video
      id="vide"
      :preload="preload"
      poster="../../assets/img/home/ji_gou.png"
      class="home-video"
      align="center"
      :controls="controls"
      :autoplay="autoplay"
      loop="loop"
      :disablePictureInPicture="true"
    >
      <source :src="videoes[0].video " type="video/mp4" />
    </video>
    <div class="button_box">
      <div class="left_box" v-if="flag == false" @click="suspend">立即播放</div>
      <div class="left_box" v-else-if="flag == true" @click="suspend">
        暂停播放
      </div>
      <div class="right_box" @click="tiyan">马上体验</div>
      <!-- <div class="right_box">建设中，敬请期待</div> -->
    </div>
    <div class="bottom_box" @click="xia_bu">
      <img class="icones" src="../../assets/img/home/arrow-bottom.png" />
      <p class="text">往下滑动</p>
    </div>
  </div>
</template>

<script>
import { Info } from "@/common/js/api";
import { video_list, lesson_type } from "../../views/common/js/api";
export default {
  name: "videoes",
  data() {
    return {
      videoSrc: "../../assets/video/ke_hui.mp4",
      videoImg: "../../../../static/full_res.jpg",
      playStatus: "",
      muteStatus: "",
      isMute: true,
      isPlay: false,
      width: "100%", // 设置视频播放器的显示宽度（以像素为单位）
      // height: 'auto', // 设置视频播放器的显示高度（以像素为单位）
      preload: "auto", //  建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
      controls: false, // 确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
      autoplay: false,
      videosrc: "",
      flag: false,
      heightFlag: false,
      videoes: [],
    };
  },
  filters: {
    ImgFilter(value) {
      return "http://hout.kehui.cloud" + value;
    },
  },
  created() {
    this.vlists();
  },
  methods: {
    vlists() {
      video_list({})
        .then((res) => {
          this.videoes = res.data;
        })
        .catch((err) => {});
    },
    suspend() {
      var vide = document.getElementById("vide");
      if (vide.paused) {
        vide.play();

        this.flag = true;
      } else if (vide.play()) {
        vide.pause();
        this.flag = false;
      }
    },
    tiyan() {
     
      Info({
        id: JSON.parse(localStorage.getItem("user")).id,
      })
        .then((res) => {
          this.info = res.data;
          if (this.info.provider) {
            this.$router.push({
              path: "/serviceListHome",
              query: {
                id: this.info.provider.id,
              },
            });
          } else {
            this.$router.push({
              path: "/service_s",
            });
          }
        })
        .catch(() => {});
    },
    xia_bu() {
      const returnEle = document.querySelector("#productId"); //productId是将要跳转区域的id
      if (!!returnEle) {
        returnEle.scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start", // 上边框与视窗顶部平齐。默认值
        }); // true 是默认的
      }
      document.querySelector("counter1").scrollIntoView({
        behavior: "smooth", // 平滑过渡
        block: "start", // 上边框与视窗顶部平齐。默认值
      }); //这里的counter1是将要返回地方的id
    },
  },
};
</script>
<style scoped lang="less">
@keyframes move-data-v-452c731c {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.bottom_box {
  position: absolute;
  left: 50%;
  bottom: 55px;
  font-size: 14px;
  color: #ff4a2b;
  transform: translateX(-50%);
  text-align: center;
  cursor: pointer;
  .icones {
    width: 49px;
    height: 49px;
    -webkit-animation: move-data-v-452c731c 1s linear infinite !important;
    animation: move-data-v-452c731c 1s linear infinite !important;
    margin: 0 auto 13px;
  }
}
.button_box {
  position: absolute;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  .left_box {
    width: 233px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: linear-gradient(90deg, #ff7d4a, #ff4a2b);
    border-radius: 29px;
    font-size: 19px;
    color: #fff;
    cursor: pointer;
  }
  .right_box {
    margin-left: 30px;
    width: 233px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: linear-gradient(90deg, #41d47c, #4179f9);
    border-radius: 29px;
    font-size: 19px;
    color: #fff;
    cursor: pointer;
  }
}
.big_container {
  position: relative;
  // height: calc(100vh -116px);
}
.home-video {
  object-fit: fill;
}
video {
  margin: 15px 0px;
  height: calc(100vh - 100px);
  width: 100%;
}
</style>