<template>
	<div>
		<kefuright></kefuright>
		<home-swiper> </home-swiper>
		<!-- 高校 -->
		<div class="gaoxiao pt60 animate__animated animate__fadeInUp">
			<div class="banxin">
				<div class="qiyetop" id="gao">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">高校</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="gaoul">
					<div class="gaobg">
						<div class="gaotitle oneline">
							高校
						</div>
						<div class="gaotxt forelines">
							 平台提供技术转移转化、知识产权、政策运用咨询、科技金融、科技项目全过程咨询等各类服务，先后与西南科技大学、等大学合作，特别是组织策划“四川大学调研交流”、“两院院士四川行达州对接会”。 
						</div>
						<div class="jing">
							<div class="jingleft" @click="jr(1)">
								进入高校
							</div>
							<div class="jingleft" @click="cheng_guo">
								进入成果发布入口
							</div>
						</div>
					</div>
					<div class="daxueul">
						<div class="daxueli" v-for="(item, index) in gao_xiao" :key="index" @click="xq(item.id)">
							<div class="datitle oneline">
								{{ item.title }}
							</div>
							<img :src="urlImg + item.img" alt="" class="dapic" />
							<div class="datxt twolines" v-html=" item.describe">
							</div>
							<!-- <div class="jietxt" @click="xq(item.id)">
								了解更多 >
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 科研院所 -->
		<div class="zongbg">
			<div class="pt60" id="ke">
				<div class="gongtop">
					<img src="../../../assets/img/images/tbl_08.png" class="zhengpic" alt="" />
					<div class="zhengbai">科研院所</div>
					<img src="../../../assets/img/images/tbr_08.png" class="zhengpic" alt="" />
				</div>
				<div class="peotitle">
					平台机构积极与各个研院所合作，协调省农业机械研究设计院、省机械研究设计院等院所工作，助推产业合作
				</div>
			</div>
		</div>
		<div class="jingbox">
			<div class="banxin">
				<div class="jingbai">
					<div class="caibox">
						<div v-for="(list, index) in dataList" :key="index" class="caili" @click="xqaa(list.id)">
							<div class="caititle oneline">
								{{list.title}}
							</div>
							<img :src="urlImg + list.img" class="caipic"  alt="" />
							<div class="caitxt twolines" v-html="list.describe">
							</div>
							<!-- <div class="cailan" @click="xqaa(imgList.id)">
								了解更多 >
							</div> -->
						</div>
					</div>
							
					<div class="zhuboxa" style="padding-top: 20px;">
						<div class="zhuce" @click="jr(2)">进入专题页面</div>
						<div class="zhuceact" @click="cheng_guo">进入成果发布入口</div>
					</div>

				</div>
			</div>
		</div>
		<!-- 其他科研组织 -->
		<div class="gaoxiao" id="qi">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">其他科研组织</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="gaoul">
					<div class="gaobg">
						<div class="gaotitle oneline">
							其他科研组织
						</div>
						<div class="gaotxt twolines">
							辐射和吸引其它科研组织，技术成果饱满丰富。
						</div>
						<div class="jing">
							<div class="jingleft" @click="jr(3)">
								进入其他科研组织
							</div>
							<div class="jingleft" @click="cheng_guo">
								进入成果发布入口
							</div>
						</div>
					</div>
					<div class="daxueul">
						<div class="daxueli" v-for="(item, index) in qt"  :key="index" @click="xq(item.id)">
							<div class="datitle oneline">
								{{ item.title }}
							</div>
							<img :src="urlImg + item.img" class="dapic" alt="" />
							<div class="datxt twolines" v-html=" item.describe"></div>
							<!-- <div class="jietxt" @click="xq(item.id)">
								了解更多 >
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 创新个人 -->
		<div class="gaoxiao" id="qi">
			<div class="banxin">
				<div class="qiyetop">
					<img src="../../../assets/img/images/tl_06.png" class="zhengpic" alt="" />
					<div class="zhengtitle">创新个人</div>
					<img src="../../../assets/img/images/tr_07.png" class="zhengpic" alt="" />
				</div>
				<div class="gaoul">
					<div class="gaobg">
						<div class="gaotitle oneline">
							创新个人
						</div>
						<div class="gaotxt twolines">
							引导创新个人发展，激发培养全面人才。
						</div>
						<div class="jing">
							<div class="jingleft" @click="jr(100)">
								进入创新个人
							</div>
							<div class="jingleft" @click="cheng_guo">
								进入成果发布入口
							</div>
						</div>
					</div>
					<div class="daxueul">
						<div class="daxueli" v-for="(item, index) in cx" :key="index" @click="xq(item.id)">
							<div class="datitle oneline">
								{{ item.title }}
							</div>
							<img :src="urlImg + item.img" class="dapic" alt="" />
							<div class="datxt twolines" v-html=" item.describe"></div>
							<!-- <div class="jietxt" @click="xq(item.id)">
								了解更多 >
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import kefuright from '../../../components/common/kefu';
	import $ from "jquery";
	import HomeSwiper from "../../../components/common/Swiper";
	import FromList from "../../../common/input";
	import city from "../../qiyeyun/base/city";
	import {
		urlImg
	} from "@/common/js/url";
	import {
		Wisdom,
		achievementsLists,
		Data
	} from "../../../common/js/api";
	import {
		employer_organ
	} from "../../common/js/api";
	import {
		zhi
	} from "../../common/js/api";
	import {
		formatDate
	} from "../../../common/js/times"; //时间

	export default {
		name: "zhichanyunForm",
		components: {
			FromList,
			HomeSwiper,
			city,
			kefuright,
			NumberRoller: () => import("@/common/number-roller.vue"),
		},
		data() {
			return {
				gao_xiao: [],
				ky: [],
				qt: [],
				cx: [],
				dataList: [],
			};
		},
		created() {
			this.getGaoXiao();
			this.getKY();
			this.getQT();
			this.getCX();
			this.urlImg = urlImg();
		},
		mounted() {
			this.getDataSource()
			var hash = window.location.hash;
			// console.log(window.location.hash);
			var index = hash.lastIndexOf("#");
			if (index != -1) {
				var id = hash.substring(index + 1, hash.length + 1);
				var div = document.getElementById(id);
				if (div) {
					setTimeout(function() {
						// console.log($(div).offset().top);
						//$('html,body').scrollTop($(div).offset().top - 43);
						$("html, body").animate({
							scrollTop: $(div).offset().top
						}, 300);
					}, 500);
				}
			}
		},
		methods: {
			// 列表
			getGaoXiao() {
				employer_organ({
						type: 108,
						page: 1,
						limits: 3,
					})
					.then((res) => {
						this.gao_xiao = res.data.data;


					})
					.catch((err) => {});
			},

			// 进入成果发布入口
			cheng_guo() {
				this.$router.push({
					path: "/sucessresult",
				});
			},
			getKY() {
				employer_organ({
						type: 109,
						page: 1,
						limits: 3,
					})
					.then((res) => {
						this.dataList = res.data.data;
						console.log(this.dataList)
					})
					.catch((err) => {});
			},
			getQT() {
				employer_organ({
						type: 110,
						page: 1,
						limits: 3,
					})
					.then((res) => {
						this.qt = res.data.data;
					})
					.catch((err) => {});
			},
			getCX() {
				employer_organ({
						type: 111,
						page: 1,
						limits: 3,
					})
					.then((res) => {
						this.cx = res.data.data;
					})
					.catch((err) => {});
			},
			//   大学详情
			xq(id) {
				this.$router.push({
					path: "/GaoDetail",
					query: {
						ids: id,
					},
				});
			},
			//   详情
			xqaa(id) {
			  this.$router.push({
				path: "/GaoDetail",
				query: {
				  ids: id,
				 },
			  });
			},
			// 进入
			jr(i) {
				var id = "";
				if (i == 1) {
					id = 108;
				} else if (i == 2) {
					id = 109;
				} else if (i == 3) {
					id = 110;
				} else {
					id = 111;
				}
				this.$router.push({
					path: "/gaoxiao",
					query: {
						id: id, //108高校，109科研院所，110其他科研组织，111创新个人
					},
				});
			},
			getDataSource() {
				let newDataList = []
				let current = 0
				if (this.dataList && this.dataList.length > 0) {
					for (let i = 0; i <= this.dataList.length - 1; i++) {
						if (i % 3 !== 0 || i === 0) {
							if (!newDataList[current]) {
								newDataList.push([this.dataList[i]])
							} else {
								newDataList[current].push(this.dataList[i])
							}
						} else {
							current++
							newDataList.push([this.dataList[i]])
						}
					}
				}
				this.dataList = [...newDataList]
			},




		}
	};
</script>

<style scoped lang="less">
	.el-car-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}

	.banxin {
		width: 1400px;
		margin: 0 auto;
	}

	.pt60 {
		padding-top: 60px;
	}

	.qiyetop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 60px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}

	.gongtop {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 25px;

		.zhengpic {
			width: 118px;
			height: 27px;
			flex-shrink: 0;
		}

		.zhengtitle {
			font-size: 36px;
			color: #222222;
			font-weight: bold;
			padding: 0 35px;
		}

		.zhengbai {
			font-size: 36px;
			color: #ffffff;
			font-weight: bold;
			padding: 0 35px;
		}
	}



	.zongbg {
		width: 100%;
		height: 380px;
		background: url('../../../assets/img/images/loubg_06.png') no-repeat center;
		background-size: 100% 380px;

		.peotitle {
			font-size: 16px;
			color: #ffffff;
			text-align: center;
		}
	}



	/deep/.el-carousel__button {
		background-color: transparent;
		height: 5px !important;
		width: 20px !important;
		border-radius: 25px;
		background-color: transparent;
		opacity: 0.3;
	}

	/deep/.el-carousel__indicator.is-active button {
		background-color: transparent;
		height: 5px !important;
		width: 20px !important;
		border-radius: 25px;
	}

	/deep/.el-carousel__indicators--horizontal {
		bottom: 5px;
	}

	/deep/.el-carousel__arrow {
		background-color: transparent !important;
		right: 1px;
	}
	/deep/.el-carousel__arrow i{
		font-size: 40px;
		color: #aaaaaa;
	}
	/deep/.el-carousel__arrow--left {
		left: 0;
	}
	
	/deep/.el-carousel__arrow i:hover{
		color: #0289f4 !important;
	}
	

	.jingbox {
		display: flex;
		background-color: #f6f6f6;
		padding-bottom: 60px;

		.jingbai {
			padding: 30px 20px 40px;
			background-color: #ffffff;
			border-radius: 7px;
			margin-top: -210px;
			.caibox{
				display: flex;
				align-items: center;
			}
			.caili {
				padding: 0 30px;
				width: 430px;
				height: 450px;
				background-color: #ffffff;
				box-shadow: 0 0 5px #dddddd;
				border: 1px solid #eeeeee;
				margin-right: 35px;
				flex-shrink: 0;
				transition: all 0.5s;
				.caititle {
					font-size: 24px;
					color: #222222;
					font-weight: bold;
					padding: 30px 0;
				}
			
				.caipic {
					width: 100%;
					height: 225px;
				}
			
				.caitxt {
					font-size: 14px;
					color: #888888;
					padding: 23px 0 0;
					margin-bottom: 56px;
					line-height: 28px;
				}
			
				.cailan {
					cursor: pointer;
					color: #0289f4;
					font-size: 16px;
				}
			}
			.caili:hover{
				background-color: #0289f4;
				.caititle{
					color: #ffffff;
				}
				.caitxt{
					color: #ffffff;
				}
			}

			.wintop {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 60px;
				margin-bottom: 30px;

				.wline {
					width: 383px;
					height: 1px;
					background-color: #dddddd;
				}

				.winpic {
					width: 22px;
					height: 23px;
					margin: 0 12px;
				}

				.wintitle {
					font-size: 30px;
					color: #333333;
					font-weight: bold;
					padding: 0 20px;
				}
			}

			.winpic {
				width: 100%;
				height: 525px;
			}

			.zhuboxa {
				display: flex;
				align-items: center;
				justify-content: center;
				
				.zhuce {
					width: 205px;
					height: 40px;
					margin: 0 20px;
					cursor: pointer;
					background-color: #ff4200;
					border: 1px solid #ff4200;
					font-size: 18px;
					color: #ffffff;
					text-align: center;
					border-radius: 5px;
					line-height: 40px;
					transition: all 0.5s;
				}
				.zhuce:hover{
					transform: translateY(-10%);
				}
				.zhuceact {
					width: 205px;
					height: 40px;
					margin: 0 20px;
					cursor: pointer;
					border: 1px solid #ff4200;
					font-size: 18px;
					color: #ff4200;
					text-align: center;
					border-radius: 5px;
					line-height: 40px;
					transition: all 0.5s;
				}
				.zhuceact:hover{
					transform: translateY(-10%);
				}
			}
		}
	}

	.pt60 {
		padding-top: 60px !important;
	}

	.gaoxiao {
		padding: 0 0 60px;
		background-color: #f6f6f6;

		.gaoul {
			display: flex;
			align-items: center;

			.gaobg {
				width: 340px;
				height: 340px;
				border-radius: 5px;
				flex-shrink: 0;
				background: url('../../../assets/img/images/gaobg_05.png') no-repeat center;
				background-size: 340px 340px;
				padding: 0 25px;

				.gaotitle {
					font-size: 28px;
					color: #ffffff;
					padding: 38px 0 18px;
					font-weight: bold;
				}

				.gaotxt {
					font-size: 18px;
					color: #ffffff;
					line-height: 30px;
					height: 120px;
				}

				.jing {
					padding-top: 65px;
					display: flex;
					align-items: center;
					.jingleft {
						width: 140px;
						height: 35px;
						border: 1px solid #ffffff;
						text-align: center;
						line-height: 35px;
						font-size: 16px;
						color: #ffffff;
						cursor: pointer;
						transition: all 0.5s;
						margin-right: 10px;
					}
					.jingleft:hover {
						width: 140px;
						height: 35px;
						border: 1px solid #ffffff;
						background-color: #ffffff;
						text-align: center;
						line-height: 35px;
						font-size: 16px;
						color: #0289f4;
						cursor: pointer;
						transform: translateY(-10%);
					}
				}
			}

			.daxueul {
				display: flex;
				align-items: center;

				.daxueli {
					margin-left: 13.3px;
					width: 340px;
					height: 340px;
					box-shadow: 0 0 5px #dddddd;
					border-radius: 5px;
					background-color: #ffffff;
					padding: 0 30px;
					cursor: pointer;
					transition: all 0.5s;
					.datitle {
						font-size: 24px;
						color: #222222;
						font-weight: bold;
						padding: 30px 0 22px;
					}

					.dapic {
						width: 100%;
						height: 160px;
					}

					.datxt {
						padding: 25px 0 0;
						font-size: 14px;
						line-height: 24px;
						color: #888888;
					}

					.jietxt {
						cursor: pointer;
						font-size: 16px;
						color: #0073e5;
						margin-top: 22px;
					}
				}
				.daxueli:hover{
					transform: translateY(-5%);
					background-color: #0289f4;
					.datitle {
						color: #ffffff;
					}
					.datxt{
						color: #ffffff;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.banxin{
			width: 98%;
			margin: 0 auto;
			display: block;
		}
		.jingbox .jingbai .caibox{
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.jingbox .jingbai .caili{
			width: 49%;
			margin: 0 0 2rem 0;
		}
		.w{
			width: 98%;
			margin: 0 auto;
			display: block;
		}
		.gaoxiao .gaoul{
			flex-wrap: wrap;
		}
		.gaoxiao .gaoul .daxueul{
			flex-wrap: wrap;
			justify-content: space-between;
		}
		.qiyetop .zhengtitle{
			font-size: 3rem;
		}
		.gaoxiao .gaoul .gaobg{
			width: 100%;
			background-size: cover;
		}
		.gaoxiao .gaoul .gaobg .gaotitle{
			font-size: 2rem;
		}
		.gaoxiao .gaoul .gaobg .gaotxt{
			font-size: 1.6rem;
			width: 60%;
			-webkit-line-clamp: 2;
			line-height: 2.2rem;
			height: auto;
		}
		.gaoxiao .gaoul .gaobg .jing{
			justify-content: flex-start;
			padding-top: 3rem;
		}
		.gaoxiao .gaoul .gaobg .jing .jingleft{
			width: 16rem;
			height: 3rem;
			line-height: 3rem;
			margin-right: 2rem;
			font-size: 1.8rem;
		}
		.gaoxiao .gaoul .gaobg .jing .jingright{
			width: 16rem;
			height: 3rem;
			line-height: 3rem;
			margin-right: 4rem;
			font-size: 1.8rem;
		}
		.gaoxiao .gaoul .daxueul .daxueli:nth-child(3){
			display: none;
		}
		.gaoxiao .gaoul .daxueul .daxueli{
			width: 49%;
			margin: 1rem 0 0 0;
			height: 26rem;
		}
		.gaoxiao .gaoul .daxueul .daxueli .datitle{
			font-size: 1.8rem;
		}
		.gaoxiao .gaoul .daxueul .daxueli .dapic{
			height: 12rem;
		}
		.gaoxiao .gaoul .daxueul .daxueli .datxt{
			font-size: 1.6rem;
			line-height: 2.2rem;
		}
		.gaoxiao .gaoul .daxueul .daxueli .jietxt{
			font-size: 1.6rem;
		}
		
		.zongbg{
			height: 23rem;
			background-size: 100% 23rem;
		}
		.jingbox .jingbai .caili:nth-child(3){
			display: none;
		}
		.jingbox .jingbai .caili .caititle{
			font-size: 1.6rem;
			padding: 1rem 0;
		}
		.jingbox .jingbai .caili .caitxt{
			font-size: 1.4rem;
			line-height: 1.8rem;
			margin-bottom: 2rem;
		}
		.jingbox .jingbai .caili .cailan{
			font-size: 1.4rem;
		}
		.gongtop .zhengbai{
			font-size: 3rem;
		}
		.zongbg .peotitle{
			font-size: 1.6rem;
			line-height: 2.2rem;
			padding: 0 3rem;
		}
	}
</style>